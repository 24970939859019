import React from 'react';
import { 
  Grid, 
  Card, 
  CardContent, 
  Typography, 
  Box, 
  useTheme,
  alpha
} from '@mui/material';
import {
  MedicalServices as LabOrderIcon,
  AccessTime as VisitIcon,
  Favorite as HealthRecordsIcon,
  Message as MessageIcon
} from '@mui/icons-material';

function Homecards() {
  const theme = useTheme();

  // Card configuration for consistent styling
  const cardConfigs = [
    {
      title: 'Lab Orders',
      icon: <LabOrderIcon color="primary" />,
      status: 'Coming Soon',
      color: theme.palette.primary.main
    },
    {
      title: 'Upcoming Visits',
      icon: <VisitIcon color="primary" />,
      status: 'Coming Soon',
      color: theme.palette.primary.main
    },
    {
      title: 'Health Records',
      icon: <HealthRecordsIcon color="primary" />,
      status: 'Coming Soon',
      color: theme.palette.primary.main
    },
    {
      title: 'Messages',
      icon: <MessageIcon color="primary" />,
      status: 'Coming Soon',
      color: theme.palette.primary.main
    }
  ];

  // Reusable Card Component
  const HomeCard = ({ title, icon, status, color }) => (
    <Card 
      sx={{ 
        height: '100%', 
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'space-between',
        transition: 'transform 0.3s ease-in-out',
        '&:hover': {
          transform: 'scale(1.02)',
          boxShadow: theme.shadows[4]
        }
      }}
      elevation={2}
    >
      <CardContent>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          mb: 2 
        }}>
          <Typography 
            variant="h6" 
            color="text.secondary" 
            sx={{ fontWeight: 600 }}
          >
            {title}
          </Typography>
          <Box 
            sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: 'center',
              width: 40,
              height: 40,
              borderRadius: '50%',
              backgroundColor: alpha(color, 0.1)
            }}
          >
            {icon}
          </Box>
        </Box>
        <Typography 
          variant="body2" 
          color="text.secondary"
          sx={{ 
            fontWeight: 500,
            color: color,
            display: 'flex',
            alignItems: 'center'
          }}
        >
          {status}
        </Typography>
      </CardContent>
    </Card>
  );

  return (
    <Grid container spacing={2} sx={{ width: '100%' }}>
      {cardConfigs.map((config, index) => (
        <Grid item xs={12} sm={6} key={index}>
          <HomeCard {...config} />
        </Grid>
      ))}
    </Grid>
  );
}

export default Homecards;