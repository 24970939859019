export const SubstanceSection = ({ formData, isEditing, setFormData }) => {
    const handleTobaccoTypeChange = (type, value) => {
      if (!isEditing) return;
      setFormData(prev => ({
        ...prev,
        substanceUse: {
          ...prev.substanceUse,
          tobaccoUse: {
            ...prev.substanceUse.tobaccoUse,
            currentSubAnswer: {
              ...prev.substanceUse.tobaccoUse.currentSubAnswer,
              type: {
                ...prev.substanceUse.tobaccoUse.currentSubAnswer.type,
                [type]: value
              }
            }
          }
        }
      }));
    };
  
    const handleTobaccoUseChange = (value) => {
      if (!isEditing) return;
      const updatedTobaccoUse = {
        current: "", former: "", never: "",
        [value]: "1"
      };
      setFormData(prev => ({
        ...prev,
        substanceUse: {
          ...prev.substanceUse,
          tobaccoUse: {
            ...prev.substanceUse.tobaccoUse,
            ...updatedTobaccoUse
          }
        }
      }));
    };
  
    const handleAlcoholUseChange = (value) => {
      if (!isEditing) return;
      const updatedAlcoholUse = {
        current: "", former: "", never: "",
        [value]: "1"
      };
      setFormData(prev => ({
        ...prev,
        substanceUse: {
          ...prev.substanceUse,
          alcoholUse: {
            ...prev.substanceUse.alcoholUse,
            ...updatedAlcoholUse
          }
        }
      }));
    };
  
    return (
      <section className="mb-5">
        <h2 className="h4 mb-3">Substance Use</h2>
  
        {/* Tobacco Use */}
        <div className="mb-4">
          <label className="form-label text-primary">Tobacco use</label>
          <div className="d-flex gap-2">
            {[
              { key: "current", label: "Current" },
              { key: "former", label: "Former" },
              { key: "never", label: "Never" }
            ].map((option) => (
              <div key={option.key}>
                <input
                  type="radio"
                  className="btn-check"
                  name="tobacco_use"
                  id={`tobacco_${option.key}`}
                  checked={formData.substanceUse.tobaccoUse[option.key] === "1"}
                  onChange={() => handleTobaccoUseChange(option.key)}
                  disabled={!isEditing}
                />
                <label className="btn btn-outline-primary" htmlFor={`tobacco_${option.key}`}>
                  {option.label}
                </label>
              </div>
            ))}
          </div>
  
          {/* Current Tobacco Use Type */}
          {formData.substanceUse.tobaccoUse.current === "1" && (
            <div className="mt-3">
              <label className="form-label">Type</label>
              <div className="d-flex flex-wrap gap-2">
                {[
                  { key: "cigarettes", label: "Cigarettes" },
                  { key: "cigars", label: "Cigars" },
                  { key: "chewingTobacco", label: "Chewing Tobacco" },
                  { key: "vaping", label: "Vaping" },
                  { key: "other", label: "Other" }
                ].map((option) => (
                  <div key={option.key} className="w-100">
                    <input
                      type="radio"
                      className="btn-check"
                      name="tobacco_type"
                      id={`type_${option.key}`}
                      checked={formData.substanceUse.tobaccoUse.currentSubAnswer?.type?.[option.key] === "1"}
                      onChange={() => handleTobaccoTypeChange(option.key, "1")}
                      disabled={!isEditing}
                    />
                    <label className="btn btn-outline-primary w-100" htmlFor={`type_${option.key}`}>
                      {option.label}
                    </label>
                  </div>
                ))}
              </div>
  
              {/* Cigarettes Quantity */}
              {formData.substanceUse.tobaccoUse.currentSubAnswer?.type?.cigarettes === "1" && (
                <div className="mt-3">
                  <label className="form-label">How many?</label>
                  <div className="d-flex flex-wrap gap-2">
                    {[
                      { key: "oneToThree", label: "1-3 a day" },
                      { key: "halfAPack", label: "1/2 a pack" },
                      { key: "onePack", label: "1 pack" },
                      { key: "moreThanOne", label: "More than 1 pack" },
                      { key: "other", label: "Other" }
                    ].map((option) => (
                      <div key={option.key} className="w-100">
                        <input
                          type="radio"
                          className="btn-check"
                          name="cigarette_quantity"
                          id={`quantity_${option.key}`}
                          checked={formData.substanceUse.tobaccoUse.currentSubAnswer?.type?.cigarettesSubAnswer?.[option.key] === "1"}
                          onChange={() => {
                            if (!isEditing) return;
                            setFormData(prev => ({
                              ...prev,
                              substanceUse: {
                                ...prev.substanceUse,
                                tobaccoUse: {
                                  ...prev.substanceUse.tobaccoUse,
                                  currentSubAnswer: {
                                    ...prev.substanceUse.tobaccoUse.currentSubAnswer,
                                    type: {
                                      ...prev.substanceUse.tobaccoUse.currentSubAnswer.type,
                                      cigarettesSubAnswer: {
                                        ...prev.substanceUse.tobaccoUse.currentSubAnswer.type.cigarettesSubAnswer,
                                        oneToThree: "", halfAPack: "", onePack: "", moreThanOne: "", other: "",
                                        [option.key]: "1"
                                      }
                                    }
                                  }
                                }
                              }
                            }));
                          }}
                          disabled={!isEditing}
                        />
                        <label className="btn btn-outline-primary w-100" htmlFor={`quantity_${option.key}`}>
                          {option.label}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}
  
          {/* Former Tobacco Use */}
          {formData.substanceUse.tobaccoUse.former === "1" && (
            <div className="mt-3">
              <label className="form-label">When did you quit?</label>
              <div className="d-flex flex-wrap gap-2">
                {[
                  { key: "withinLastYear", label: "Stopped within the last year" },
                  { key: "withinLastThreeYear", label: "Stopped within the last 3 years" },
                  { key: "moreYearAgo", label: "Stopped 5 or more years ago" }
                ].map((option) => (
                  <div key={option.key} className="w-100">
                    <input
                      type="radio"
                      className="btn-check"
                      name="quit_when"
                      id={`quit_${option.key}`}
                      checked={formData.substanceUse.tobaccoUse.formerSubAnswer?.when?.[option.key] === "1"}
                      onChange={() => {
                        if (!isEditing) return;
                        setFormData(prev => ({
                          ...prev,
                          substanceUse: {
                            ...prev.substanceUse,
                            tobaccoUse: {
                              ...prev.substanceUse.tobaccoUse,
                              formerSubAnswer: {
                                ...prev.substanceUse.tobaccoUse.formerSubAnswer,
                                when: {
                                  withinLastYear: "",
                                  withinLastThreeYear: "",
                                  moreYearAgo: "",
                                  [option.key]: "1"
                                }
                              }
                            }
                          }
                        }));
                      }}
                      disabled={!isEditing}
                    />
                    <label className="btn btn-outline-primary w-100" htmlFor={`quit_${option.key}`}>
                      {option.label}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
  

        Preview
Code

{/* Alcohol Use */}
<div className="mb-4">
  <label className="form-label text-primary">Have you ever consumed alcohol?</label>
  <div className="d-flex gap-2">
    {[
      { key: "current", label: "Current" },
      { key: "former", label: "Former" },
      { key: "never", label: "Never" }
    ].map((option) => (
      <div key={option.key}>
        <input
          type="radio"
          className="btn-check"
          name="alcohol_use"
          id={`alcohol_${option.key}`}
          checked={formData.substanceUse.alcoholUse[option.key] === "1"}
          onChange={() => handleAlcoholUseChange(option.key)}
          disabled={!isEditing}
        />
        <label className="btn btn-outline-primary" htmlFor={`alcohol_${option.key}`}>
          {option.label}
        </label>
      </div>
    ))}
  </div>
</div>

{formData.substanceUse.alcoholUse.current === "1" && (
  <div className="mt-3">
    {/* Current Alcohol Use */}
    <div className="current-alcohol-use">
      {/* Frequency of Alcohol Consumption */}
      <div className="mb-3">
        <label className="form-label">How often do you have a drink containing alcohol?</label>
        <div className="d-flex flex-wrap gap-2">
          {[
            { key: "never", label: "Never (+0)", value: "0" },
            { key: "monthly", label: "Monthly or less (+1)", value: "1" },
            { key: "twoTimesMonth", label: "2 to 4 times a month (+2)", value: "2" },
            { key: "twoTimesWeek", label: "2 to 3 times a week (+3)", value: "3" },
            { key: "fourTimesWeek", label: "4 or more times a week (+4)", value: "4" }
          ].map((option) => (
            <div key={option.key} className="w-100">
              <input
                type="radio"
                className="btn-check"
                name="current_drink_frequency"
                id={`current_freq_${option.key}`}
                checked={formData.substanceUse.alcoholUse.currentSubAnswer?.howMnyTimes?.oneOrMoreSubAnswers?.howOften?.[option.key] === "1"}
                onChange={() => {
                  if (!isEditing) return;
                  setFormData(prev => ({
                    ...prev,
                    substanceUse: {
                      ...prev.substanceUse,
                      alcoholUse: {
                        ...prev.substanceUse.alcoholUse,
                        currentSubAnswer: {
                          ...prev.substanceUse.alcoholUse.currentSubAnswer,
                          howMnyTimes: {
                            ...prev.substanceUse.alcoholUse.currentSubAnswer.howMnyTimes,
                            oneOrMoreSubAnswers: {
                              howOften: {
                                never: "", monthly: "", twoTimesMonth: "", twoTimesWeek: "", fourTimesWeek: "",
                                [option.key]: "1"
                              },
                              comment: prev.substanceUse.alcoholUse.currentSubAnswer?.howMnyTimes?.oneOrMoreSubAnswers?.howOften.comment || `Frequency of alcohol consumption: ${option.label}`
                            }
                          }
                        }
                      }
                    }
                  }));
                }}
                disabled={!isEditing}
              />
              <label className="btn btn-outline-primary w-100" htmlFor={`current_freq_${option.key}`}>
                {option.label}
              </label>
            </div>
          ))}
        </div>
        <input
          type="text"
          className="form-control mt-2"
          value={formData.substanceUse.alcoholUse.currentSubAnswer?.howMnyTimes?.oneOrMoreSubAnswers?.howOften?.comment || ""}
          onChange={(e) => {
            if (!isEditing) return;
            setFormData(prev => ({
              ...prev,
              substanceUse: {
                ...prev.substanceUse,
                alcoholUse: {
                  ...prev.substanceUse.alcoholUse,
                  currentSubAnswer: {
                    ...prev.substanceUse.alcoholUse.currentSubAnswer,
                    howMnyTimes: {
                      ...prev.substanceUse.alcoholUse.currentSubAnswer.howMnyTimes,
                      oneOrMoreSubAnswers: {
                        ...prev.substanceUse.alcoholUse.currentSubAnswer.howMnyTimes.oneOrMoreSubAnswers,
                        howOften: {
                          ...prev.substanceUse.alcoholUse.currentSubAnswer.howMnyTimes.oneOrMoreSubAnswers.howOften,
                          comment: e.target.value
                        }
                      }
                    }
                  }
                }
              }
            }));
          }}
          disabled={!isEditing}
          placeholder="Additional comments about frequency (optional)"
        />
      </div>

      {/* Number of Drinks */}
      <div className="mb-3">
        <label className="form-label">How many standard drinks containing alcohol do you have on a typical day?</label>
        <div className="d-flex flex-wrap gap-2">
          {[
            { key: "oneOrTwo", label: "1 or 2 (+0)", value: "0" },
            { key: "threeOrFour", label: "3 or 4 (+1)", value: "1" },
            { key: "fiveOrSix", label: "5 or 6 (+2)", value: "2" },
            { key: "sevenEight", label: "7-8 (+3)", value: "3" },
            { key: "tenOrMore", label: "10 or more (+4)", value: "4" }
          ].map((option) => (
            <div key={option.key} className="w-100">
              <input
                type="radio"
                className="btn-check"
                name="current_drink_quantity"
                id={`current_quantity_${option.key}`}
                checked={formData.substanceUse.alcoholUse.currentSubAnswer?.howMnyTimes?.oneOrMoreSubAnswers?.howManyDrinks?.[option.key] === "1"}
                onChange={() => {
                  if (!isEditing) return;
                  setFormData(prev => ({
                    ...prev,
                    substanceUse: {
                      ...prev.substanceUse,
                      alcoholUse: {
                        ...prev.substanceUse.alcoholUse,
                        currentSubAnswer: {
                          ...prev.substanceUse.alcoholUse.currentSubAnswer,
                          howMnyTimes: {
                            ...prev.substanceUse.alcoholUse.currentSubAnswer.howMnyTimes,
                            oneOrMoreSubAnswers: {
                              ...prev.substanceUse.alcoholUse.currentSubAnswer.howMnyTimes.oneOrMoreSubAnswers,
                              howManyDrinks: {
                                oneOrTwo: "", threeOrFour: "", fiveOrSix: "", sevenEight: "", tenOrMore: "",
                                [option.key]: "1"
                              }
                            }
                          }
                        }
                      }
                    }
                  }));
                }}
                disabled={!isEditing}
              />
              <label className="btn btn-outline-primary w-100" htmlFor={`current_quantity_${option.key}`}>
                {option.label}
              </label>
            </div>
          ))}
        </div>
      </div>

      {/* Binge Drinking */}
      <div className="mb-3">
        <label className="form-label">How often do you have six or more drinks on one occasion?</label>
        <div className="d-flex flex-wrap gap-2">
          {[
            { key: "never", label: "Never (+0)", value: "0" },
            { key: "lessThanMonthly", label: "Less than Monthly (+1)", value: "1" },
            { key: "monthly", label: "Monthly (+2)", value: "2" },
            { key: "weekly", label: "Weekly (+3)", value: "3" },
            { key: "daily", label: "Daily or almost daily (+4)", value: "4" }
          ].map((option) => (
            <div key={option.key} className="w-100">
              <input
                type="radio"
                className="btn-check"
                name="current_six_drinks_frequency"
                id={`current_six_drinks_${option.key}`}
                checked={formData.substanceUse.alcoholUse.currentSubAnswer?.howMnyTimes?.oneOrMoreSubAnswers?.sixOrMore?.[option.key] === "1"}
                onChange={() => {
                  if (!isEditing) return;
                  setFormData(prev => ({
                    ...prev,
                    substanceUse: {
                      ...prev.substanceUse,
                      alcoholUse: {
                        ...prev.substanceUse.alcoholUse,
                        currentSubAnswer: {
                          ...prev.substanceUse.alcoholUse.currentSubAnswer,
                          howMnyTimes: {
                            ...prev.substanceUse.alcoholUse.currentSubAnswer.howMnyTimes,
                            oneOrMoreSubAnswers: {
                              ...prev.substanceUse.alcoholUse.currentSubAnswer.howMnyTimes.oneOrMoreSubAnswers,
                              sixOrMore: {
                                never: "", lessThanMonthly: "", monthly: "", weekly: "", daily: "",
                                [option.key]: "1"
                              }
                            }
                          }
                        }
                      }
                    }
                  }));
                }}
                disabled={!isEditing}
              />
              <label className="btn btn-outline-primary w-100" htmlFor={`current_six_drinks_${option.key}`}>
                {option.label}
              </label>
            </div>
          ))}
        </div>
      </div>

      {/* Audit Score */}
      <div className="mb-3">
        <label className="form-label">Audit C Score</label>
        <input
          type="text"
          className="form-control"
          value={(() => {
            const { howOften, howManyDrinks, sixOrMore } = formData.substanceUse.alcoholUse.currentSubAnswer?.howMnyTimes?.oneOrMoreSubAnswers || {};
            return (howOften?.never ? 0 : howOften?.monthly ? 1 : howOften?.twoTimesMonth ? 2 : howOften?.twoTimesWeek ? 3 : howOften?.fourTimesWeek ? 4 : 0) +
              (howManyDrinks?.oneOrTwo ? 0 : howManyDrinks?.threeOrFour ? 1 : howManyDrinks?.fiveOrSix ? 2 : howManyDrinks?.sevenEight ? 3 : howManyDrinks?.tenOrMore ? 4 : 0) +
              (sixOrMore?.never ? 0 : sixOrMore?.lessThanMonthly ? 1 : sixOrMore?.monthly ? 2 : sixOrMore?.weekly ? 3 : sixOrMore?.daily ? 4 : 0);
          })() || ""}
          onChange={(e) => {
            if (!isEditing) return;
            setFormData(prev => ({
              ...prev,
              substanceUse: {
                ...prev.substanceUse,
                alcoholUse: {
                  ...prev.substanceUse.alcoholUse,
                  currentSubAnswer: {
                    ...prev.substanceUse.alcoholUse.currentSubAnswer,
                    howMnyTimes: {
                      ...prev.substanceUse.alcoholUse.currentSubAnswer.howMnyTimes,
                      oneOrMoreSubAnswers: {
                        ...prev.substanceUse.alcoholUse.currentSubAnswer.howMnyTimes.oneOrMoreSubAnswers
                      }
                    }
                  }
                }
              }
            }));
          }}
          disabled={!isEditing}
        />
        <small className="text-muted d-block">
          Men: a score of 4 or more is considered positive<br />
          Women: a score of 3 or more is considered positive
        </small>
      </div>
    {/* Recovery Status */}
    <div className="mt-3">
      <label className="form-label">Are you currently in recovery for alcohol or substance use?</label>
      <div className="d-flex gap-2">
        {[
          { value: "1", label: "Yes" },
          { value: "2", label: "No" }
        ].map((option) => (
          <div key={option.value}>
            <input
              type="radio"
              className="btn-check"
              name="recovery_status"
              id={`recovery_${option.value}`}
              checked={formData.substanceUse.alcoholUse.currentSubAnswer?.currentlyInRecovery?.isCurrentlyInRecovery === option.value}
              onChange={() => {
                if (!isEditing) return;
                setFormData(prev => ({
                  ...prev,
                  substanceUse: {
                    ...prev.substanceUse,
                    alcoholUse: {
                      ...prev.substanceUse.alcoholUse,
                      currentSubAnswer: {
                        ...prev.substanceUse.alcoholUse.currentSubAnswer,
                        currentlyInRecovery: {
                          ...prev.substanceUse.alcoholUse.currentSubAnswer.currentlyInRecovery,
                          isCurrentlyInRecovery: option.value
                        }
                      }
                    }
                  }
                }));
              }}
              disabled={!isEditing}
            />
            <label className="btn btn-outline-primary" htmlFor={`recovery_${option.value}`}>
              {option.label}
            </label>
          </div>
        ))}
      </div>
  </div>
  </div>
  </div>
)}

        {/* Education Checkbox */}
        <div className="mb-3 form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id="education_provided"
            checked={formData.substanceUse.educationProvided.isEducationProvided === "1"}
            onChange={() => {
              if (!isEditing) return;
              setFormData(prev => ({
                ...prev,
                substanceUse: {
                  ...prev.substanceUse,
                  educationProvided: {
                    ...prev.substanceUse.educationProvided,
                    isEducationProvided: prev.substanceUse.educationProvided.isEducationProvided === "1" ? "" : "1"
                  }
                }
              }));
            }}
            disabled={!isEditing}
          />
          <label className="form-check-label" htmlFor="education_provided">
            Education provided regarding alcohol use
          </label>
        </div>

       {/* Education Guidelines */}
<div className="mb-4">
  <label className="form-label text-primary">
    How many times in the past year have you used a recreational drug or used a prescription medication for nonmedical reasons?
  </label>
  <div className="d-flex gap-2">
    {[
      { key: "isNone", label: "None" },
      { key: "oneOrMore", label: "1 or more" }
    ].map((option) => (
      <div key={option.key}>
        <input
          type="radio"
          className="btn-check"
          name="recreational_drug_use"
          id={`drug_${option.key}`}
          checked={formData.educationGuidlines?.pastYear?.[option.key] === "1"}
          onChange={() => {
            if (!isEditing) return;
            setFormData(prev => ({
              ...prev,
              educationGuidlines: {
                ...prev.educationGuidlines,
                pastYear: {
                  isNone: "",
                  oneOrMore: "",
                  comment: prev.educationGuidlines?.pastYear?.comment || "",
                  [option.key]: "1"
                }
              }
            }));
          }}
          disabled={!isEditing}
        />
        <label className="btn btn-outline-primary" htmlFor={`drug_${option.key}`}>
          {option.label}
        </label>
      </div>
    ))}
  </div>

  {/* Comment Field */}
  <div className="mt-3">
    <label className="form-label">Additional Comments</label>
    <textarea
      className="form-control"
      value={formData.educationGuidlines?.pastYear?.comment || ""}
      onChange={(e) => {
        if (!isEditing) return;
        setFormData(prev => ({
          ...prev,
          educationGuidlines: {
            ...prev.educationGuidlines,
            pastYear: {
              ...prev.educationGuidlines?.pastYear,
              comment: e.target.value
            }
          }
        }));
      }}
      disabled={!isEditing}
    />
  </div>
</div>

      </section>
    );
  };