export const AdvanceCareSection = ({ formData, isEditing, setFormData }) => {
    return (
      <section className="mb-5">
        <h2 className="h4 mb-3">Advanced Care Planning</h2>
  
        {/* Healthcare Proxy */}
        <div className="mb-4">
          <label className="form-label text-primary">Do you have a Healthcare Proxy?</label>
          <div className="d-flex gap-2">
            {[
              { value: "1", label: "Yes" },
              { value: "2", label: "No" },
              { value: "3", label: "Don't Know" }
            ].map((option) => (
              <div key={option.value}>
                <input
                  type="radio"
                  className="btn-check"
                  name="healthcare_proxy"
                  id={`proxy_${option.value}`}
                  checked={formData.advanceCarePlanning.healthCareProxy.isHealthCareProxy === option.value}
                  onChange={() => {
                    if (!isEditing) return;
                    setFormData(prev => ({
                      ...prev,
                      advanceCarePlanning: {
                        ...prev.advanceCarePlanning,
                        healthCareProxy: {
                          ...prev.advanceCarePlanning.healthCareProxy,
                          isHealthCareProxy: option.value
                        }
                      }
                    }));
                  }}
                  disabled={!isEditing}
                />
                <label className="btn btn-outline-primary" htmlFor={`proxy_${option.value}`}>
                  {option.label}
                </label>
              </div>
            ))}
          </div>
  
          {formData.advanceCarePlanning.healthCareProxy.isHealthCareProxy === "1" && (
            <div className="mt-3">
              <div className="mb-3">
                <label className="form-label">Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={formData.advanceCarePlanning.healthCareProxy.isHealthCareProxySubAnswer?.name || ""}
                  onChange={(e) => {
                    if (!isEditing) return;
                    setFormData(prev => ({
                      ...prev,
                      advanceCarePlanning: {
                        ...prev.advanceCarePlanning,
                        healthCareProxy: {
                          ...prev.advanceCarePlanning.healthCareProxy,
                          isHealthCareProxySubAnswer: {
                            ...prev.advanceCarePlanning.healthCareProxy.isHealthCareProxySubAnswer,
                            name: e.target.value
                          }
                        }
                      }
                    }));
                  }}
                  disabled={!isEditing}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Relationship</label>
                <input
                  type="text"
                  className="form-control"
                  value={formData.advanceCarePlanning.healthCareProxy.isHealthCareProxySubAnswer?.relationship || ""}
                  onChange={(e) => {
                    if (!isEditing) return;
                    setFormData(prev => ({
                      ...prev,
                      advanceCarePlanning: {
                        ...prev.advanceCarePlanning,
                        healthCareProxy: {
                          ...prev.advanceCarePlanning.healthCareProxy,
                          isHealthCareProxySubAnswer: {
                            ...prev.advanceCarePlanning.healthCareProxy.isHealthCareProxySubAnswer,
                            relationship: e.target.value
                          }
                        }
                      }
                    }));
                  }}
                  disabled={!isEditing}
                />
              </div>
            </div>
          )}
        </div>
  
        {/* Power of Attorney */}
        <div className="mb-4">
          <label className="form-label text-primary">Do you have a Durable Power of Attorney?</label>
          <div className="d-flex gap-2">
            {[
              { value: "1", label: "Yes" },
              { value: "2", label: "No" },
              { value: "3", label: "Don't Know" }
            ].map((option) => (
              <div key={option.value}>
                <input
                  type="radio"
                  className="btn-check"
                  name="power_attorney"
                  id={`power_${option.value}`}
                  checked={formData.advanceCarePlanning.powerAttorney.isPowerAttorney === option.value}
                  onChange={() => {
                    if (!isEditing) return;
                    setFormData(prev => ({
                      ...prev,
                      advanceCarePlanning: {
                        ...prev.advanceCarePlanning,
                        powerAttorney: {
                          ...prev.advanceCarePlanning.powerAttorney,
                          isPowerAttorney: option.value
                        }
                      }
                    }));
                  }}
                  disabled={!isEditing}
                />
                <label className="btn btn-outline-primary" htmlFor={`power_${option.value}`}>
                  {option.label}
                </label>
              </div>
            ))}
          </div>
  
          {formData.advanceCarePlanning.powerAttorney.isPowerAttorney === "1" && (
            <div className="mt-3">
              <div className="mb-3">
                <label className="form-label">Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={formData.advanceCarePlanning.powerAttorney.isPowerAttorneySubAnswer?.name || ""}
                  onChange={(e) => {
                    if (!isEditing) return;
                    setFormData(prev => ({
                      ...prev,
                      advanceCarePlanning: {
                        ...prev.advanceCarePlanning,
                        powerAttorney: {
                          ...prev.advanceCarePlanning.powerAttorney,
                          isPowerAttorneySubAnswer: {
                            ...prev.advanceCarePlanning.powerAttorney.isPowerAttorneySubAnswer,
                            name: e.target.value
                          }
                        }
                      }
                    }));
                  }}
                  disabled={!isEditing}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Relationship</label>
                <input
                  type="text"
                  className="form-control"
                  value={formData.advanceCarePlanning.powerAttorney.isPowerAttorneySubAnswer?.relationship || ""}
                  onChange={(e) => {
                    if (!isEditing) return;
                    setFormData(prev => ({
                      ...prev,
                      advanceCarePlanning: {
                        ...prev.advanceCarePlanning,
                        powerAttorney: {
                          ...prev.advanceCarePlanning.powerAttorney,
                          isPowerAttorneySubAnswer: {
                            ...prev.advanceCarePlanning.powerAttorney.isPowerAttorneySubAnswer,
                            relationship: e.target.value
                          }
                        }
                      }
                    }));
                  }}
                  disabled={!isEditing}
                />
              </div>
            </div>
          )}
        </div>
  
        {/* Advance Directive */}
        <div className="mb-4">
          <label className="form-label text-primary">Do you have an Advance Directive?</label>
          <div className="d-flex gap-2">
            {[
              { value: "1", label: "Yes" },
              { value: "2", label: "No" },
              { value: "3", label: "Don't Know" }
            ].map((option) => (
              <div key={option.value}>
                <input
                  type="radio"
                  className="btn-check"
                  name="advance_directive"
                  id={`directive_${option.value}`}
                  checked={formData.advanceCarePlanning.advanceDirective.isAdvanceDirective === option.value}
                  onChange={() => {
                    if (!isEditing) return;
                    setFormData(prev => ({
                      ...prev,
                      advanceCarePlanning: {
                        ...prev.advanceCarePlanning,
                        advanceDirective: {
                          ...prev.advanceCarePlanning.advanceDirective,
                          isAdvanceDirective: option.value
                        }
                      }
                    }));
                  }}
                  disabled={!isEditing}
                />
                <label className="btn btn-outline-primary" htmlFor={`directive_${option.value}`}>
                  {option.label}
                </label>
              </div>
            ))}
          </div>
  
          {formData.advanceCarePlanning.advanceDirective.isAdvanceDirective === "1" && (
            <div className="mt-3">
              <label className="form-label">Where is it kept?</label>
              <input
                type="text"
                className="form-control"
                value={formData.advanceCarePlanning.advanceDirective.isAdvanceDirectiveSubAnswer?.kept || ""}
                onChange={(e) => {
                  if (!isEditing) return;
                  setFormData(prev => ({
                    ...prev,
                    advanceCarePlanning: {
                      ...prev.advanceCarePlanning,
                      advanceDirective: {
                        ...prev.advanceCarePlanning.advanceDirective,
                        isAdvanceDirectiveSubAnswer: {
                          kept: e.target.value
                        }
                      }
                    }
                  }));
                }}
                disabled={!isEditing}
              />
            </div>
          )}
        </div>
  
        {/* Education Checkbox */}
        <div className="mb-3 form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id="education_planning"
            checked={formData.advanceCarePlanning.educatedOnAdvanceCarePlanning.isEducatedOnAdvanceCarePlanning === "1"}
            onChange={() => {
              if (!isEditing) return;
              setFormData(prev => ({
                ...prev,
                advanceCarePlanning: {
                  ...prev.advanceCarePlanning,
                  educatedOnAdvanceCarePlanning: {
                    ...prev.advanceCarePlanning.educatedOnAdvanceCarePlanning,
                    isEducatedOnAdvanceCarePlanning: prev.advanceCarePlanning.educatedOnAdvanceCarePlanning.isEducatedOnAdvanceCarePlanning === "1" ? "" : "1"
                  }
                }
              }));
            }}
            disabled={!isEditing}
          />
          <label className="form-check-label" htmlFor="education_planning">
            Member educated on advance care planning
          </label>
        </div>
  
        {/* Declines Discussion Checkbox */}
        <div className="mb-3 form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id="declines_discussion"
            checked={formData.advanceCarePlanning.declinesDiscussion.isDeclinesDiscussion === "1"}
            onChange={() => {
              if (!isEditing) return;
              setFormData(prev => ({
                ...prev,
                advanceCarePlanning: {
                  ...prev.advanceCarePlanning,
                  declinesDiscussion: {
                    ...prev.advanceCarePlanning.declinesDiscussion,
                    isDeclinesDiscussion: prev.advanceCarePlanning.declinesDiscussion.isDeclinesDiscussion === "1" ? "" : "1"
                  }
                }
              }));
            }}
            disabled={!isEditing}
          />
          <label className="form-check-label" htmlFor="declines_discussion">
            Declined discussion at this time
          </label>
        </div>
      </section>
    );
  };