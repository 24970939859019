import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "./store/actions/auth_action";
import {
  Menu as MenuIcon,
  Close as CloseIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Person as PersonIcon,
  Dashboard as DashboardIcon,
  Email as EmailIcon,
  Phone as PhoneIcon,
  Settings as SettingsIcon,
  HelpOutline as HelpIcon,
  Logout as LogoutIcon,
} from "@mui/icons-material";
import {
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  AppBar,
  Toolbar,
  Menu,
  MenuItem,
  Typography,
  Box,
  Tooltip,
  useTheme,
  alpha,
} from "@mui/material";
import Dashboard from "./Dashboard.js";

function MainDash() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const theme = useTheme();

  const handleLogout = () => {
    try {
      dispatch(authActions.logout());
      navigate("/MemberPortal/Login");
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // Improved sidebar content with conditional rendering for collapsed state
  const sidebarContent = (
    <Box
      sx={{
        width: isSidebarOpen ? 250 : 65,
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
        overflowX: "hidden",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          p: 2,
          minHeight: 64,
        }}
      >
        {isSidebarOpen ? (
          <Box sx={{ flex: 1, display: "flex", justifyContent: "center" }}>
            <img
              src="https://focuscares.com/wp-content/uploads/elementor/thumbs/logo-pcsu71jmplrr1f3b7mtv083rbyula7p5imfik70y8o.png"
              alt="Focus Cares"
              style={{ height: "40px" }}
            />
          </Box>
        ) : null}
        <IconButton
          onClick={toggleSidebar}
          sx={{
            ml: isSidebarOpen ? 1 : 0,
            transition: theme.transitions.create(["transform", "margin"], {
              duration: theme.transitions.duration.shorter,
              color: "black",
            }),
          }}
        >
          {isSidebarOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </Box>

      <Divider />

      <List sx={{ flexGrow: 1, backgroundColor: "whitesmoke", color: "black" }}>
        {isSidebarOpen && (
          <ListItem sx={{ pb: 2 }}>
            <Typography variant="overline" color="black">
              My Health
            </Typography>
          </ListItem>
        )}

        <Tooltip title={!isSidebarOpen ? "My Info" : ""} placement="right">
          <ListItem
            button
            onClick={() => navigate("/MemberPortal/Profile")}
            // component="a"
            // href="/MemberPortal/Profile"
            sx={{
              minHeight: 48,
              px: 2.5,
              "&:hover": {
                backgroundColor: alpha(theme.palette.primary.main, 0.08),
              },
            }}
          >
            <ListItemIcon sx={{ minWidth: isSidebarOpen ? 56 : "auto" }}>
              <PersonIcon />
            </ListItemIcon>
            {isSidebarOpen && <ListItemText primary="My Info" />}
          </ListItem>
        </Tooltip>

        <Tooltip title={!isSidebarOpen ? "Dashboard" : ""} placement="right">
          <ListItem
            button
            onClick={() => navigate("/MemberPortal/Dashboard")}
            // component="a"
            // href="/MemberPortal/Dashboard"
            selected
            sx={{
              minHeight: 48,
              px: 2.5,
              backgroundColor: alpha(theme.palette.primary.main, 0.12),
              "&:hover": {
                backgroundColor: alpha(theme.palette.primary.main, 0.08),
              },
            }}
          >
            <ListItemIcon sx={{ minWidth: isSidebarOpen ? 56 : "auto" }}>
              <DashboardIcon />
            </ListItemIcon>
            {isSidebarOpen && <ListItemText primary="Dashboard" />}
          </ListItem>
        </Tooltip>

                <Tooltip title={!isSidebarOpen ? "Forms" : ""} placement="right">
                  <ListItem
                    sx={{
                      minHeight: 48,
                      px: 2.5,
                      "&:hover": {
                        backgroundColor: alpha(theme.palette.primary.main, 0.08),
                      },
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: isSidebarOpen ? 56 : "auto" }}>
                      <PersonIcon />
                    </ListItemIcon>
                    {isSidebarOpen && <ListItemText primary="Forms" />}
                  </ListItem>
                </Tooltip>

        <Divider sx={{ my: 2, color: "black" }} />

        {isSidebarOpen && (
          <ListItem sx={{ pb: 2 }}>
            <Typography variant="overline" color="black">
              Contact Care Team
            </Typography>
          </ListItem>
        )}

        <Tooltip title={!isSidebarOpen ? "Email" : ""} placement="right">
          <ListItem
            button
            sx={{
              minHeight: 48,
              px: 2.5,
              "&:hover": {
                backgroundColor: alpha(theme.palette.primary.main, 0.08),
              },
            }}
          >
            <ListItemIcon sx={{ minWidth: isSidebarOpen ? 56 : "auto" }}>
              <EmailIcon />
            </ListItemIcon>
            {isSidebarOpen && <ListItemText primary="Email" />}
          </ListItem>
        </Tooltip>

        <Tooltip title={!isSidebarOpen ? "Contact" : ""} placement="right">
          <ListItem
            button
            sx={{
              minHeight: 48,
              px: 2.5,
              "&:hover": {
                backgroundColor: alpha(theme.palette.primary.main, 0.08),
              },
            }}
          >
            <ListItemIcon sx={{ minWidth: isSidebarOpen ? 56 : "auto" }}>
              <PhoneIcon />
            </ListItemIcon>
            {isSidebarOpen && <ListItemText primary="Contact" />}
          </ListItem>
        </Tooltip>
      </List>
    </Box>
  );

  return (
    <Box sx={{ display: "flex", height: "100vh" }}>
      {/* Mobile Drawer */}
      <Drawer
        variant="temporary"
        open={isSidebarOpen}
        onClose={toggleSidebar}
        sx={{
          display: { xs: "block", md: "none" },
          "& .MuiDrawer-paper": {
            width: 250,
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[4],
          },
        }}
      >
        {sidebarContent}
      </Drawer>

      {/* Desktop Permanent Drawer with animation */}
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", md: "block" },
          "& .MuiDrawer-paper": {
            position: "relative",
            whiteSpace: "nowrap",
            width: isSidebarOpen ? 250 : 65,
            transition: theme.transitions.create("width", {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: "border-box",
            backgroundColor: theme.palette.background.paper,
            borderRight: `1px solid ${theme.palette.divider}`,
            overflowX: "hidden",
          },
        }}
        open={isSidebarOpen}
      >
        {sidebarContent}
      </Drawer>

      {/* Main Content */}
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <AppBar
          position="static"
          color="default"
          elevation={1}
          sx={{
            backgroundColor: "whitesmoke",
            borderBottom: `1px solid ${theme.palette.divider}`,
          }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={toggleSidebar}
              sx={{
                display: { md: "none" },
                mr: 2,
                transform: isSidebarOpen ? "rotate(180deg)" : "rotate(0deg)",
                transition: theme.transitions.create("transform", {
                  duration: theme.transitions.duration.shorter,
                }),
              }}
            >
              {isSidebarOpen ? <CloseIcon /> : <MenuIcon />}
            </IconButton>

            <Box sx={{ flexGrow: 1 }} />

            <Box>
              <Tooltip title="Account settings">
                <IconButton
                  onClick={handleMenu}
                  color="inherit"
                  size="large"
                  aria-label="account"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  sx={{
                    "&:hover": {
                      backgroundColor: alpha(theme.palette.primary.main, 0.08),
                    },
                  }}
                >
                  <PersonIcon />
                </IconButton>
              </Tooltip>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                PaperProps={{
                  elevation: 3,
                  sx: {
                    minWidth: 200,
                    mt: 1,
                  },
                }}
              >
                <MenuItem
                  onClick={handleClose}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    py: 1,
                    "&:hover": {
                      backgroundColor: alpha(theme.palette.primary.main, 0.08),
                    },
                  }}
                >
                  <PersonIcon sx={{ mr: 2 }} /> Profile
                </MenuItem>
                <MenuItem
                  onClick={handleClose}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    py: 1,
                    "&:hover": {
                      backgroundColor: alpha(theme.palette.primary.main, 0.08),
                    },
                  }}
                >
                  <SettingsIcon sx={{ mr: 2 }} /> Settings
                </MenuItem>
                <MenuItem
                  onClick={handleClose}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    py: 1,
                    "&:hover": {
                      backgroundColor: alpha(theme.palette.primary.main, 0.08),
                    },
                  }}
                >
                  <HelpIcon sx={{ mr: 2 }} /> Help Center
                </MenuItem>
                <Divider />
                <MenuItem
                  onClick={handleLogout}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    py: 1,
                    color: theme.palette.error.main,
                    "&:hover": {
                      backgroundColor: alpha(theme.palette.error.main, 0.08),
                    },
                  }}
                >
                  <LogoutIcon sx={{ mr: 2 }} /> Log out
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </AppBar>

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            overflow: "auto",
            backgroundColor: alpha(theme.palette.primary.main, 0.02),
          }}
        >
          <Dashboard />
        </Box>
      </Box>
    </Box>
  );
}

export default MainDash;
