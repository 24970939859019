export const AUTH_TYPES = {
    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILURE: 'LOGIN_FAILURE',
  
    REGISTER_REQUEST: 'REGISTER_REQUEST',
    REGISTER_SUCCESS: 'REGISTER_SUCCESS',
    REGISTER_FAILURE: 'REGISTER_FAILURE',
    
    LOGOUT: 'LOGOUT',
  
    SEND_OTP_REQUEST: 'SEND_OTP_REQUEST',
    SEND_OTP_SUCCESS: 'SEND_OTP_SUCCESS',
    SEND_OTP_FAILURE: 'SEND_OTP_FAILURE',
  
    VALIDATE_OTP_REQUEST: 'VALIDATE_OTP_REQUEST',
    VALIDATE_OTP_SUCCESS: 'VALIDATE_OTP_SUCCESS',
    VALIDATE_OTP_FAILURE: 'VALIDATE_OTP_FAILURE',
  
    UPDATE_PASSWORD_REQUEST: 'UPDATE_PASSWORD_REQUEST',
    UPDATE_PASSWORD_SUCCESS: 'UPDATE_PASSWORD_SUCCESS',
    UPDATE_PASSWORD_FAILURE: 'UPDATE_PASSWORD_FAILURE',
  };
  