export const FoodSection = ({ formData, isEditing, setFormData }) => {
  return (
    <section className="mb-5">
      <h2 className="h4 mb-3">Food</h2>

      <div className="mb-4">
        <label className="form-label text-primary">
          Within the past 12 months we worried whether our food would run out before we got money to buy more. Was that _____ for your household?
        </label>
        <div className="d-flex gap-2">
          {[
            { key: "oftenTrue", label: "Often True" },
            { key: "sometimesTrue", label: "Sometimes True" },
            { key: "neverTrue", label: "Never True" }
          ].map((option) => (
            <div key={option.key}>
              <input
                type="radio"
                className="btn-check"
                name="food_worry"
                id={`food_${option.key}`}
                checked={formData.food.foodWouldRunOut[option.key] === "1"}
                onChange={() => {
                  if (!isEditing) return;
                  const updatedFood = {
                    oftenTrue: "",
                    sometimesTrue: "",
                    neverTrue: "",
                    [option.key]: "1"
                  };
                  setFormData(prev => ({
                    ...prev,
                    food: {
                      ...prev.food,
                      foodWouldRunOut: {
                        ...prev.food.foodWouldRunOut,
                        ...updatedFood
                      }
                    }
                  }));
                }}
                disabled={!isEditing}
              />
              <label className="btn btn-outline-primary" htmlFor={`food_${option.key}`}>
                {option.label}
              </label>
            </div>
          ))}
        </div>

        {formData.food.foodWouldRunOut.oftenTrue === "1" && (
          <div className="mt-3">
            <label className="form-label">Select</label>
            <div className="d-flex flex-wrap gap-2">
              {[
                { key: "foodWeBought", label: "The food we bought just didn't last" },
                { key: "dontHaveMoney", label: "We don't have money to get more" }
              ].map((option) => (
                <div key={option.key} className="w-100">
                  <input
                    type="radio"
                    className="btn-check"
                    name="often_true_reason"
                    id={`often_${option.key}`}
                    checked={formData.food.foodWouldRunOut.oftenTrueSubAnswers?.[option.key] === "1"}
                    onChange={() => {
                      if (!isEditing) return;
                      const updatedOftenTrueAnswers = {
                        foodWeBought: "",
                        dontHaveMoney: "",
                        [option.key]: "1"
                      };
                      setFormData(prev => ({
                        ...prev,
                        food: {
                          ...prev.food,
                          foodWouldRunOut: {
                            ...prev.food.foodWouldRunOut,
                            oftenTrueSubAnswers: {
                              ...prev.food.foodWouldRunOut.oftenTrueSubAnswers,
                              ...updatedOftenTrueAnswers
                            }
                          }
                        }
                      }));
                    }}
                    disabled={!isEditing}
                  />
                  <label className="btn btn-outline-primary w-100" htmlFor={`often_${option.key}`}>
                    {option.label}
                  </label>
                </div>
              ))}
            </div>
          </div>
        )}

        {formData.food.foodWouldRunOut.sometimesTrue === "1" && (
          <div className="mt-3">
            <label className="form-label">Select</label>
            <div className="d-flex flex-wrap gap-2">
              {[
                { key: "foodWeBought", label: "The food we bought just didn't last" },
                { key: "dontHaveMoney", label: "We don't have money to get more" }
              ].map((option) => (
                <div key={option.key} className="w-100">
                  <input
                    type="radio"
                    className="btn-check"
                    name="sometimes_true_reason"
                    id={`sometimes_${option.key}`}
                    checked={formData.food.foodWouldRunOut.sometimesTrueSubAnswers?.[option.key] === "1"}
                    onChange={() => {
                      if (!isEditing) return;
                      const updatedSometimesTrueAnswers = {
                        foodWeBought: "",
                        dontHaveMoney: "",
                        [option.key]: "1"
                      };
                      setFormData(prev => ({
                        ...prev,
                        food: {
                          ...prev.food,
                          foodWouldRunOut: {
                            ...prev.food.foodWouldRunOut,
                            sometimesTrueSubAnswers: {
                              ...prev.food.foodWouldRunOut.sometimesTrueSubAnswers,
                              ...updatedSometimesTrueAnswers
                            }
                          }
                        }
                      }));
                    }}
                    disabled={!isEditing}
                  />
                  <label className="btn btn-outline-primary w-100" htmlFor={`sometimes_${option.key}`}>
                    {option.label}
                  </label>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </section>
  );
};