// LiteracySection.jsx
export const LiteracySection = ({ formData, isEditing, setFormData }) => {
    const handleRadioChange = (section, value) => {
      if (!isEditing) return;
      setFormData(prev => ({
        ...prev,
        [section]: {
          ...prev[section],
          notAtAllConfident: "",
          notVeryConfident: "",
          confident: "",
          veryConfident: "",
          [value]: "1"
        }
      }));
    };
  
    return (
      <section className="mb-5">
        <h2 className="h4 mb-3">Literacy</h2>
        
        {/* Education Level */}
        <div className="mb-4">
          <label className="form-label text-primary">How much school have you completed?</label>
          <div className="d-flex flex-wrap">
            {[
              { key: "lessThanThirdGrade", label: "Completed less than 3rd grade" },
              { key: "lessThanEighthGrade", label: "Completed less than 8th grade" },
              { key: "lessThanTwelthGrade", label: "Completed less than 12th grade" },
              { key: "completedTwelthGrade", label: "Completed 12th grade" },
              { key: "attendedOrgraduated", label: "Attended/Graduated College" }
            ].map((option) => (
              <div key={option.key} className="w-100 mb-2">
                <input
                  type="radio"
                  className="btn-check"
                  name="school_completed"
                  id={`school_${option.key}`}
                  checked={formData.school[option.key] === "1"}
                  onChange={() => {
                    if (!isEditing) return;
                    const updatedSchool = {
                      lessThanThirdGrade: "",
                      lessThanEighthGrade: "",
                      lessThanTwelthGrade: "",
                      completedTwelthGrade: "",
                      attendedOrgraduated: "",
                      comment: formData.school.comment
                    };
                    updatedSchool[option.key] = "1";
                    setFormData(prev => ({
                      ...prev,
                      school: updatedSchool
                    }));
                  }}
                  disabled={!isEditing}
                />
                <label className="btn btn-outline-primary w-100" htmlFor={`school_${option.key}`}>
                  {option.label}
                </label>
              </div>
            ))}
          </div>
        </div>
  
        {/* Medical Forms Confidence */}
<div className="mb-4">
  <label className="form-label text-primary">How confident are you in filling out medical forms by yourself?</label>
  <div className="d-flex flex-wrap gap-2">
    {[
      { key: "notAtAllConfident", label: "Not at All Confident" },
      { key: "notVeryConfident", label: "Not Very Confident" },
      { key: "confident", label: "Confident" },
      { key: "veryConfident", label: "Very Confident" }
    ].map((option) => (
      <div key={option.key} className="w-100">
        <input
          type="radio"
          className="btn-check"
          name="medical_forms_confidence"
          id={`confidence_${option.key}`}
          checked={formData.fillingMedicalForm[option.key] === "1"}
          onChange={() => {
            if (!isEditing) return;
            const updatedConfidence = {
              notAtAllConfident: "",
              notVeryConfident: "",
              confident: "",
              veryConfident: "",
              comment: formData.fillingMedicalForm.comment
            };
            updatedConfidence[option.key] = "1";
            setFormData(prev => ({
              ...prev,
              fillingMedicalForm: updatedConfidence
            }));
          }}
          disabled={!isEditing}
        />
        <label className="btn btn-outline-primary w-100" htmlFor={`confidence_${option.key}`}>
          {option.label}
        </label>
      </div>
    ))}
  </div>
</div>
        
        {/* Written Information Difficulty */}
<div className="mb-4">
  <label className="form-label text-primary">When you get written information at a doctor's office would you say it is</label>
  <div className="d-flex flex-wrap gap-2">
    {[
      { key: "veryDifficult", label: "Very difficult" },
      { key: "someWhatDifficult", label: "Somewhat difficult" },
      { key: "easy", label: "Easy" },
      { key: "veryEasy", label: "Very easy to understand" }
    ].map((option) => (
      <div key={option.key} className="w-100">
        <input
          type="radio"
          className="btn-check"
          name="written_info"
          id={`written_${option.key}`}
          checked={formData.writtenInfo[option.key] === "1"}
          onChange={() => {
            if (!isEditing) return;
            const updatedWritten = {
              veryDifficult: "",
              someWhatDifficult: "",
              easy: "",
              veryEasy: "",
              comment: formData.writtenInfo.comment
            };
            updatedWritten[option.key] = "1";
            setFormData(prev => ({
              ...prev,
              writtenInfo: updatedWritten
            }));
          }}
          disabled={!isEditing}
        />
        <label className="btn btn-outline-primary w-100" htmlFor={`written_${option.key}`}>
          {option.label}
        </label>
      </div>
    ))}
  </div>
</div>
  
        {/* Prescription Bottle Instructions */}
<div className="mb-4">
  <label className="form-label text-primary">When you read the instructions on a prescription bottle would you say that it is</label>
  <div className="d-flex flex-wrap gap-2">
    {[
      { key: "veryDifficult", label: "Very difficult" },
      { key: "someWhatDifficult", label: "Somewhat difficult" },
      { key: "easy", label: "Easy" },
      { key: "veryEasy", label: "Very easy to understand" }
    ].map((option) => (
      <div key={option.key} className="w-100">
        <input
          type="radio"
          className="btn-check"
          name="prescription_instructions"
          id={`prescription_${option.key}`}
          checked={formData.prescriptionBottle[option.key] === "1"}
          onChange={() => {
            if (!isEditing) return;
            const updatedPrescription = {
              veryDifficult: "",
              someWhatDifficult: "",
              easy: "",
              veryEasy: "",
              comment: formData.prescriptionBottle.comment
            };
            updatedPrescription[option.key] = "1";
            setFormData(prev => ({
              ...prev,
              prescriptionBottle: updatedPrescription
            }));
          }}
          disabled={!isEditing}
        />
        <label className="btn btn-outline-primary w-100" htmlFor={`prescription_${option.key}`}>
          {option.label}
        </label>
      </div>
    ))}
  </div>
</div>
      </section>
    );
  };