// SocialSection.jsx
export const SocialSection = ({ formData, isEditing, setFormData }) => {
    return (
      <section className="mb-5">
        <h2 className="h4 mb-3">Social</h2>
  
        {/* Health Rating */}
        <div className="mb-4">
          <label className="form-label text-primary">How would you rate your health compared to other persons your age?</label>
          <div className="d-flex flex-wrap gap-2">
            {[
              { key: "excellent", label: "Excellent" },
              { key: "good", label: "Good" },
              { key: "fair", label: "Fair" },
              { key: "poor", label: "Poor" }
            ].map((option) => (
              <div key={option.key} className="w-100">
                <input
                  type="radio"
                  className="btn-check"
                  name="health_rating"
                  id={`health_${option.key}`}
                  checked={formData.social.rateYourHealth[option.key] === "1"}
                  onChange={() => {
                    if (!isEditing) return;
                    const updatedHealth = {
                      excellent: "", good: "", fair: "", poor: "", comment: formData.social.rateYourHealth.comment
                    };
                    updatedHealth[option.key] = "1";
                    setFormData(prev => ({
                      ...prev,
                      social: {
                        ...prev.social,
                        rateYourHealth: updatedHealth
                      }
                    }));
                  }}
                  disabled={!isEditing}
                />
                <label className="btn btn-outline-primary w-100" htmlFor={`health_${option.key}`}>
                  {option.label}
                </label>
              </div>
            ))}
          </div>
        </div>
  
        {/* Rely On */}
        <div className="mb-4">
          <label className="form-label text-primary">Do you have someone you can rely on to help if you are sick or have problems you need to discuss?</label>
          <div className="d-flex gap-2">
            {[
              { value: "1", label: "Yes" },
              { value: "2", label: "No" }
            ].map((option) => (
              <div key={option.value}>
                <input
                  type="radio"
                  className="btn-check"
                  name="rely_on"
                  id={`rely_${option.value}`}
                  checked={formData.social.relyOn.isRelyOn === option.value}
                  onChange={() => {
                    if (!isEditing) return;
                    setFormData(prev => ({
                      ...prev,
                      social: {
                        ...prev.social,
                        relyOn: {
                          ...prev.social.relyOn,
                          isRelyOn: option.value
                        }
                      }
                    }));
                  }}
                  disabled={!isEditing}
                />
                <label className="btn btn-outline-primary" htmlFor={`rely_${option.value}`}>
                  {option.label}
                </label>
              </div>
            ))}
          </div>
        </div>
  
        {/* Social Activities */}
        <div className="mb-4">
          <label className="form-label text-primary">During past 3 months, has your physical and or emotional health limited your social activities with family, friends, neighbors or groups?</label>
          <div className="d-flex flex-wrap gap-2">
            {[
              { key: "often", label: "Often" },
              { key: "sometimes", label: "Sometimes" },
              { key: "almostNever", label: "Almost Never" },
              { key: "never", label: "Never" }
            ].map((option) => (
              <div key={option.key} className="w-100">
                <input
                  type="radio"
                  className="btn-check"
                  name="social_activities"
                  id={`social_${option.key}`}
                  checked={formData.social.socialActivities[option.key] === "1"}
                  onChange={() => {
                    if (!isEditing) return;
                    const updatedActivities = {
                      often: "", sometimes: "", almostNever: "", never: "", comment: formData.social.socialActivities.comment
                    };
                    updatedActivities[option.key] = "1";
                    setFormData(prev => ({
                      ...prev,
                      social: {
                        ...prev.social,
                        socialActivities: updatedActivities
                      }
                    }));
                  }}
                  disabled={!isEditing}
                />
                <label className="btn btn-outline-primary w-100" htmlFor={`social_${option.key}`}>
                  {option.label}
                </label>
              </div>
            ))}
          </div>
        </div>
  
        {/* Meet Family/Friends */}
<div className="mb-4">
  <label className="form-label text-primary">How often do you go out to meet with family or friends</label>
  <div className="d-flex flex-wrap gap-2">
    {[
      { key: "often", label: "Often" },
      { key: "sometimes", label: "Sometimes" },
      { key: "never", label: "Never" },
      { key: "almostNever", label: "Almost Never" }
    ].map((option) => (
      <div key={option.key} className="w-100">
        <input
          type="radio"
          className="btn-check"
          name="meet_frequency"
          id={`meet_${option.key}`}
          checked={formData.social.goOutToMeet[option.key] === "1"}
          onChange={() => {
            if (!isEditing) return;
            const updatedMeet = {
              often: "", 
              sometimes: "", 
              never: "", 
              almostNever: "",
              comment: formData.social.goOutToMeet.comment
            };
            updatedMeet[option.key] = "1";
            setFormData(prev => ({
              ...prev,
              social: {
                ...prev.social,
                goOutToMeet: updatedMeet
              }
            }));
          }}
          disabled={!isEditing}
        />
        <label className="btn btn-outline-primary w-100" htmlFor={`meet_${option.key}`}>
          {option.label}
        </label>
      </div>
    ))}
  </div>
</div>
  
        {/* Caregiver */}
<div className="mb-4">
  <label className="form-label text-primary">Are you currently a caregiver for someone?</label>
  <div className="d-flex flex-wrap gap-2">
    {[
      { value: "1", label: "Yes" },
      { value: "2", label: "No" }
    ].map((option) => (
      <div key={option.value} className="w-100">
        <input
          type="radio"
          className="btn-check"
          name="caregiver"
          id={`care_${option.value}`}
          checked={formData.social.careGiver.isCareGiver === option.value}
          onChange={() => {
            if (!isEditing) return;
            setFormData(prev => ({
              ...prev,
              social: {
                ...prev.social,
                careGiver: {
                  isCareGiver: option.value,
                  isCareGiverSubAnswer: option.value === "2" 
                    ? { describe: "" } 
                    : prev.social.careGiver.isCareGiverSubAnswer,
                  comment: prev.social.careGiver.comment
                }
              }
            }));
          }}
          disabled={!isEditing}
        />
        <label className="btn btn-outline-primary w-100" htmlFor={`care_${option.value}`}>
          {option.label}
        </label>
      </div>
    ))}
  </div>

  {/* Caregiver Description */}
  {formData.social.careGiver.isCareGiver === "1" && (
    <div className="mt-3">
      <label className="form-label">Describe</label>
      <textarea
        className="form-control"
        value={formData.social.careGiver.isCareGiverSubAnswer?.describe || ""}
        onChange={(e) => {
          if (!isEditing) return;
          setFormData(prev => ({
            ...prev,
            social: {
              ...prev.social,
              careGiver: {
                ...prev.social.careGiver,
                isCareGiverSubAnswer: {
                  describe: e.target.value
                }
              }
            }
          }));
        }}
        disabled={!isEditing}
      />
    </div>
  )}
</div>
      </section>
    );
  };